import { GatsbySeo } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";

const PrivacyPage: React.FC = () => {
  return (
    <Layout>
      <GatsbySeo title="プライバシーポリシー" />
      <div className="prose mb-20 max-w-full prose-h2:border-l-4 prose-h2:border-yellow-400 prose-h2:pl-2">
        <h1 className="pt-12 pb-8 md:text-6xl">Privacy Policy</h1>
        <p>
          <time>2022年09月14日</time>
          制定
        </p>
        <h2>アクセス解析について</h2>
        <p>
          当サイトではアクセス解析を目的としてGoogle
          Analyticsを利用しています。アクセス解析で用いられるデータは個人を特定しない形で記録されます。詳しくは
          <a
            target="_blank"
            rel="noreferrer"
            href="https://policies.google.com/technologies/partner-sites?hl=ja"
          >
            Google ポリシーと規約
          </a>
          をご覧ください。
        </p>
        <h2>著作権について</h2>
        <p>
          本ブログサイトに投稿された文章の著作権はすべてその文章の著者に帰属します。
          著作権法により認められる場合を除き、許諾を得ることなく当サイトの内容を複製、改変、無断転載等する行為は著作権法により禁止されています。
        </p>
        <h2>免責</h2>
        <ul>
          <li>
            当サイトからリンクされている第三者のサイトに関して、リンク先ページに起因するあらゆる問題について当サイトは責任を負いません。
          </li>
          <li>
            予告なしに当サイトの情報を変更、および公開を中断することがあります。
          </li>
        </ul>
        <h2>プライバシーポリシーの更新について</h2>
        <p>
          当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。
          修正された最新のプライバシーポリシーは常に本ページ（/privacy）にて開示されます。
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPage;
